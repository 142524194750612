import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import "./CMS.scss";
import {cmsRoutes} from "../constants/routes";

const CMS = (props) => {
  return (
    <div className="CMS w-100">
      <Switch>
        {cmsRoutes.map(({path, exact, component}) => (
          <Route
            key={`${props.match.path}${path}`}
            exact={exact}
            path={`${props.match.path}${path}`}
            component={component}
          />
        ))}
        <Redirect from="*" to={props.match.path}/>
      </Switch>
    </div>
  );
};

export default CMS;
