import axios from 'axios';
import httpHeaderInterceptor from "../interceptors/httpHeaders";
import jwtErrorInterceptor from "../interceptors/jwt";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVICE_URL}/api/v1`,
  withCredentials: true
});

http.interceptors.request.use(httpHeaderInterceptor,(error) => {
  return Promise.reject(error);
});

http.interceptors.response.use(response => response, (error) => jwtErrorInterceptor(error, http));

export default http;
