import React from "react";
import Loader from "../../components/Core/Loader/Loader";

const pageLoader = props => {
  return (
    <div className="min-content-height-header d-flex justify-content-center align-content-center">
      <Loader type={props.type || "ripple"} scale={props.scale}/>
    </div>
  );
};

export default pageLoader;
