import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import "./Dashboard.scss";
import Aside from "../../components/Core/Aside/Aside";
import PageLoader from "../PageLoader/PageLoader";
import routes from "../../constants/routes";
import {connect} from "react-redux";

const Dashboard = (props) => {
  return (
    <div className="Dashboard d-flex d-flex col-12 p-0 header-padding">
      <Aside className="aside position-sticky" currentUserPrivileges={props.currentUserPrivileges}/>
      <div className="ml-auto pb-5" id="mainContent">
        <Suspense fallback={<PageLoader/>}>
          <Switch>
            {routes.filter((p) => {
              return !p.requirements ||
                (p.requirements && p.requirements.some(r => props.currentUserPrivileges[r]));
            }).map(({path, exact, component}) => (
              <Route
                key={path}
                exact={exact}
                path={path}
                component={component}
              />
            ))}
            <Redirect from="*" to="/"/>
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    currentUserPrivileges: state.coreState.currentUserPrivileges,
  };
};

export default connect(mapStateToProps)(Dashboard);
