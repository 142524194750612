import apiRoutes from "../../constants/apiRoutes";
import {replaceDynamics, updateQueryParams} from "../../helpers/url";
import http from "../../helpers/http";
import {NETWORK_ERROR_MESSAGE} from "../../constants/general";
import {SHOW_SNACKBAR, showSnackbar} from "./coreActions";
import {errorCallBack} from "../../helpers/utility";

export const SET_USER_LIST= "USER/SET_USER_LIST";
export const SET_USER_DETAIL= "USER/SET_USER_DETAIL";
export const SET_MODERATORS = "USER/SET_MODERATORS";



export const getUserList = (queryParams, as = 'userList') => {
  return dispatch => {
    let url = apiRoutes.USER_LIST;
    if (queryParams) {
      url = updateQueryParams(url, queryParams);
    }
    return http.get(url).then(
      response => {
        dispatch({
          type: SET_USER_LIST,
          payload: {userList : response.data.data, as}
        });
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting ${as}: ${error.message}` }));
        }
        dispatch({type: SET_USER_LIST, payload: {userList : {}, as}});
      }
    );
  };
};

export const getUserDetail = id => {
  return dispatch => {
    return http.get(replaceDynamics(apiRoutes.USER_DETAIL, {id})).then(
      response => {
        dispatch({type: SET_USER_DETAIL, payload: { userDetail: response.data.data}});
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting user detail: ${error.message}` }));
        }
        dispatch({type: SET_USER_DETAIL, payload: {userDetail : {}}});
      }
    );
  };
};

export const getModerators = (queryParams = {}) => {
  return dispatch => {
    return http.get(updateQueryParams(
      apiRoutes.MODERATORS,
      {limit: 100, ...queryParams})
    ).then(
      response => {
        dispatch({type: SET_MODERATORS, payload: { moderators: response.data.data}});
      },
      errorCallBack(
        dispatch,
        {type: SET_MODERATORS, payload: {moderators : {}}},
        'Error getting moderators'
      ),
    );
  };
};

