const StorageType = {
  COOKIE: 'COOKIE',
  LOCAL_STORAGE: 'LOCAL_STORAGE'
};

const Cookie = ((method = StorageType.COOKIE) => {
  const cookieCache = {};
  let localStorage;
  const defaultPath = '/';
  let hasInit = false;

  const isLocalStorageSupported = () => typeof(Storage) !== "undefined";
  const localStorageMethod =
    () => method === StorageType.LOCAL_STORAGE && isLocalStorageSupported();

  const parseCookies = (cookies) => {
    if (!!cookies === false) { return; }
    const cookiesArr = cookies.split(';');
    for (const c of cookiesArr) {
      const cookieArr = c.split('=');
      cookieCache[cookieArr[0].trim()] = cookieArr[1].trim();
    }
  };

  const init = () => {
    if(!hasInit && localStorageMethod()) {
      localStorage = window.localStorage;
      hasInit = true;
      return;
    }
    if(!hasInit) {
      parseCookies(document.cookie || '');
      hasInit = true;
    }
  };

  const put = (key, value, path=defaultPath, expire_in = 15 * 24 * 60 * 60 * 1000) => {
    init();
    if(localStorageMethod()) {
      localStorage.setItem(key, value);
      return;
    }
    const d = new Date();
    d.setTime(d.getTime() + expire_in);
    const expires = `expires=${d.toUTCString()}`;
    path = (path && path.toString().indexOf('/') === 0) ? path : '/';
    path = `path=${path}`;
    document.cookie = `${key}=${value}; ${path}; ${expires}`;
    cookieCache[key] = value;
  };
  const get = (key) =>  {
    init();
    if(localStorageMethod()) {
      return localStorage.getItem(key);
    }
    return cookieCache[key] ? cookieCache[key] : null;
  };
  const getAll = () => cookieCache || {};
  const getObject = (key) => {
    try {
      return JSON.parse(get(key));
    } catch (e) {return {};}
  };
  const putObject = (key, value, path=defaultPath) => {
    put(key, JSON.stringify(value), path);
  };

  const remove = (key, path = defaultPath) => {
    if(localStorageMethod()) {
      localStorage.removeItem(key);
      return;
    }
    put(key, null, path, -24 * 60 * 60 * 1000);
    delete cookieCache[key];
  };

  const removeAllExcept = (exceptionKeys, options, path = defaultPath) => {
    if(localStorageMethod()) {
      return;
    }
    Object.keys(cookieCache).forEach(key => {
      if(exceptionKeys.indexOf(key) < 0) {
        remove(key, options, path);
      }
    });
  };

  const removeAll = (options, path = defaultPath) => {
    removeAllExcept([], options, path);
  };

  return {
    init,
    put,
    get,
    getAll,
    getObject,
    putObject,
    remove,
    removeAllExcept,
    removeAll
  };
});

const cookie = Cookie();

cookie.asLocalStorage = Cookie(StorageType.LOCAL_STORAGE);

export default cookie;
