const regExp = {
  NUMBER: /^$|^[-]?\d*$/,
  POSITIVE_NUMBER: /^\d*$/,
  PERCENTAGE: /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/,
  DATE: /^$|^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
  POSITIVE_NUMBER_DECIMAL_POINT: /^$|^([1-9][\\d]*|[0])(([.][\\d]+)?)$/,
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-z][a-z0-9.-]+[.][a-z]{2,5}$/,
  PHONE_NUMBER: /^$|^(([+][0-9]{1,3}[- ]?[0-9]{3})|([0-9]{4}))[- ]?[0-9]{3}[- ]?[0-9]{4,}$/,
  ALPHA_NUMERIC: /^$|^[a-zA-Z0-9][a-zA-Z0-9_\- ]*$/,
  ALPHA_NUMERIC_COMMA_DOT: /^$|^[a-zA-Z0-9][a-zA-Z0-9_,. '\\-]*$/,
  ALPHA_NUMERIC_PLANE: /^[a-zA-Z0-9 ]*$/,
  URL: RegExp('^$|^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])+)\\.)+[a-z]{2,})'+ // domain name
    '(\\:\\d+)?(\\/[-a-z\\d%_.&~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i')
};

export default regExp;
