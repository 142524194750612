import React from 'react';
import {Route, Switch} from "react-router-dom";
import { Provider } from "react-redux";
import AppSnackbar from "../components/UI/Snackbar/Snackbar";
import './App.scss';
import store from "../store";
import ErrorBoundary from "../components/Core/ErrorBoundary/ErrorBoundary";
import AcceptInvite from "./Pages/AcceptInvite/AcceptInvite";
import PostAuth from "./PostAuth/PostAuth";

function App() {
  return (
    <Provider store={store}>
      <AppSnackbar/>
      <div className="App">
        <ErrorBoundary>
          <Switch>
            <Route path="/accept-invite" component={AcceptInvite}/>
            <Route path="/" component={PostAuth}/>
          </Switch>
        </ErrorBoundary>
      </div>
    </Provider>
  );
}

export default App;
