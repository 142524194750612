import { Validators } from "../helpers/validator";
import regExp from "../constants/regExp";

const acceptInviteModel = (email) => {
  return {
    controls: [
      {
        name: 'email',
        parentProps: {
          type: 'input',
          label: 'Email',
          errorLabel: 'Email',
        },
        inputProps: {
          type: 'text',
          className: 'form-control',
          id: 'email',
          placeholder: 'Enter email',
          'aria-describedby': 'emailHelp',
          disabled: true,
        },
        validators: [
          {name: Validators.REQUIRED},
          {name: Validators.PATTERN, value: regExp.EMAIL}
        ],
        validity: {
          isValid: false,
          errors: []
        },
        value: email
      },
      {
        name: 'name',
        parentProps: {
          type: 'input',
          label: 'Name',
          wrapperClass: 'max-w',
          errorLabel: 'Name',
        },
        inputProps: {
          type: 'text',
          className: 'form-control',
          id: 'name',
          placeholder: 'Enter your full name',
          'aria-describedby': 'nameHelp',
        },
        validators: [
          {name: Validators.REQUIRED}
        ],
        validity: {
          isValid: false,
          errors: []
        },
        value: ''
      },

      {
        name: 'password',
        parentProps: {
          type: 'input',
          label: 'Password',
          wrapperClass: 'max-w',
          errorLabel: 'Password',
        },
        inputProps: {
          type: 'password',
          className: 'form-control',
          id: 'password',
          placeholder: 'Enter password',
          'aria-describedby': 'passwordHelp',
        },
        validators: [
          {name: Validators.REQUIRED},
          {name: Validators.MIN_LENGTH, value: 8},
        ],
        validity: {
          isValid: false,
          errors: []
        },
        value: '',
      },
      {
        name: 'confirmPassword',
        parentProps: {
          type: 'input',
          label: 'Confirm Password',
          wrapperClass: 'max-w',
          errorLabel: 'Confirm password',
        },
        inputProps: {
          type: 'password',
          className: 'form-control',
          id: 'confirmPassword',
          placeholder: 'Confirm new password',
          'aria-describedby': 'confirmPasswordHelp',
        },
        validators: [
          {name: Validators.REQUIRED},
        ],
        validity: {
          isValid: false,
          errors: []
        },
        value: '',
      }
    ],
    validators: [
      { name: Validators.EQUALS, value: {ctr1: 'confirmPassword', ctr2: 'password'} }
    ],
    validity: {
      isValid: false,
      errors: []
    }
  };
};

export default acceptInviteModel;
