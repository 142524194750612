import React, { Component } from "react";
import { hardRedirect } from "../../../helpers/url";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: '',
    errorInfo: ''
  };

  componentDidCatch(error, errorInfo) {
    this.setState({hasError: true, errorMessage: error, errorInfo});
  }

  render() {
    return this.state.hasError ? (
      <div className="ErrorBoundary">
        <h3>Something went wrong</h3>
        <p>{String(this.state.errorMessage)}</p>
        <p>{String(this.state.errorInfo)}</p>
        <button onClick={() => hardRedirect()} 
          className="btn btn-primary font-weight-600">Refresh Page</button>
      </div>
    ): this.props.children;
  }
}

export default ErrorBoundary;
