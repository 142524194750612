import React from "react";
import CheckAuth from "../CheckAuth/CheckAuth";
import Dashboard from "../Dashboard/Dashboard";

function PostAuth() {
  return (
    <CheckAuth>
      <Dashboard/>
    </CheckAuth>
  );
}

export default PostAuth;
