import * as actionTypes from "../actions/orderActions";

const initialState = {
  orderList: null,
};

const orderReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case actionTypes.SET_ORDER_LIST:
        return {
          ...state,
          orderList: action.payload.orderList
        };
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default orderReducer;
