import {SET_EVENTS} from "../actions/eventActions";

const initialState = {
  upcoming: null,
  past: null,
};

const eventReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case SET_EVENTS:
        return {
          ...state,
          [action.payload.type]: action.payload.data
        };
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default eventReducer;
