import {extractFormData} from "../../helpers/form";

export const SET_FORM_CONTROL_VALUE = "FORM/SET_FORM_CONTROL_VALUE";
export const SET_FORM_CONTROL_PROP = "FORM/SET_FORM_CONTROL_PROP";
export const SET_FORM_CONTROL_VALIDATIONS = "FORM/SET_FORM_CONTROL_VALIDATIONS";
export const RESET_FORM_DATA = "FORM/RESET_FORM_DATA";

export const getFormHandlers = (dispatch, formName) => {
  return {
    inputChangedHandler: (el, event) => {
      let newValue;
      switch (el.parentProps.type) {
        case "switch":
        case "checkbox":
          newValue = event.target.checked;
          break;
        case "rte":
          newValue = event.target.hasText ? event.target.value : '';
          break;
        default:
          newValue = event.target.value;
          break;
      }
      dispatch({
        type: SET_FORM_CONTROL_VALUE,
        payload: {
          formName,
          controlName: el.name,
          value: newValue
        }
      });
    },
    resetForm: (newForm) => {
      dispatch({
        type: RESET_FORM_DATA,
        payload: {formName, form: newForm}
      });
    },
    setControlValidations: () => {
      dispatch({
        type: SET_FORM_CONTROL_VALIDATIONS,
        payload: {
          formName,
        }
      });
    },
    extractFormData,
  };
};
