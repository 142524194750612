import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import {errorCallBack, getDaysInterval, logOut} from "../../helpers/utility";
import {updateQueryParams} from "../../helpers/url";
import { NETWORK_ERROR_MESSAGE } from "../../constants/general";

export const SHOW_SNACKBAR = "CORE/SHOW_SNACKBAR";
export const CLEAR_SNACKBAR = "CORE/CLEAR_SNACKBAR";
export const SET_OVERVIEW_SALES = "CORE/SET_OVERVIEW_SALES";
export const SET_OVERVIEW_SALES_STATS_7 = "CORE/SET_OVERVIEW_SALES_STATS_7";
export const SET_OVERVIEW_SALES_STATS = "CORE/SET_OVERVIEW_SALES_STATS";
export const SET_CATEGORIES = "CORE/SET_CATEGORIES";
export const SET_OVERVIEW_COURSES = "CORE/SET_OVERVIEW_COURSES";
export const SET_SYSTEM_OVERVIEW = "CORE/SET_SYSTEM_OVERVIEW";
export const SET_CURRENT_USER = "CORE/SET_CURRENT_USER";
export const SET_USERS_STATS = "CORE/SET_USERS_STATS";
export const SET_CURRENT_USER_PRIVILEGES = "CORE/SET_CURRENT_USER_PRIVILEGES";

export const showSnackbar = config => {
  return dispatch => {
    dispatch({ type: SHOW_SNACKBAR, payload: {config} });
  };
};

export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: CLEAR_SNACKBAR });
  };
};

export function getCurrentUser() {
  return function(dispatch) {
    return http.get(apiRoutes.USER_PROFILE).then(response => {
      dispatch({type: SET_CURRENT_USER, payload: {user: response.data.data}});
    }).catch((e) => {
      const { data } = (e.response || {});
      if (data && [ 401, 404 ].includes(data.code)) {
        dispatch({ type: SHOW_SNACKBAR, payload: {
          config: {message: 'Session Expired!', onClose: () => logOut(true, '/auth/sign-in')}
        }});
        return;
      }
      if(!data) {
        dispatch({ type: SHOW_SNACKBAR, payload: {
          config: {message: NETWORK_ERROR_MESSAGE}
        } });
      } else {
        dispatch({ type: SHOW_SNACKBAR, payload: {config: {message: data.message}} });
      }
    });
  };
}

export const getSystemOverview = (mounted = true) => {
  return dispatch => {
    return http.get(apiRoutes.SYSTEM_OVERVIEW).then(
      response => {
        if(mounted) {
          dispatch({type: SET_SYSTEM_OVERVIEW, payload: {systemOverview: response.data.data}});
        }
      },
      errorCallBack(
        dispatch,
        {type: SET_SYSTEM_OVERVIEW, payload: {systemOverview : {}}},
        'Error getting System Overview'
      ),
    );
  };
};

export const getUserStats = (queryParams = {}) => {
  return dispatch => {
    return http.get(updateQueryParams(
      apiRoutes.SUBSCRIPTION_ENGAGEMENT_STATISTICS, queryParams
    )).then(
      response => {
        dispatch({type: SET_USERS_STATS, payload: { userStats: response.data.data}});
      },
      errorCallBack(
        dispatch,
        {type: SET_USERS_STATS, payload: {userStats : {}}},
        'Error getting user statistics'
      ),
    );
  };
};

export const getSalesStats = (queryParams = {}) => {
  return dispatch => {
    return http.get(updateQueryParams(apiRoutes.SALES_STATISTICS, queryParams)).then(
      response => {
        dispatch({type: SET_OVERVIEW_SALES_STATS, payload: { salesStats: response.data.data}});
      },
      errorCallBack(
        dispatch,
        {type: SET_OVERVIEW_SALES_STATS, payload: {salesStats : {}}},
        'Error getting sales statistics'
      ),
    );
  };
};

export const getCategories = (queryParams = {}) => {
  return dispatch => {
    return http.get(updateQueryParams(
      apiRoutes.CATEGORIES,
      {paginateResponse: true, limit: 50, orderBy: 'created_at,DESC', ...queryParams})
    ).then(
      response => {
        dispatch({type: SET_CATEGORIES, payload: { categories: response.data.data}});
      },
      errorCallBack(
        dispatch,
        {type: SET_CATEGORIES, payload: {categories : {}}},
        'Error getting categories'
      ),
    );
  };
};

export const getOverviewData = (queryParams) => {
  return dispatch => {
    let salesURL = apiRoutes.SALES;
    let courseURL = apiRoutes.COURSES_ANALYTICS;
    let salesStatURL = apiRoutes.SALES_STATISTICS;
    if (queryParams && queryParams.sales) {
      salesURL = updateQueryParams(salesURL, queryParams.sales);
    }
    if (queryParams && queryParams.course) {
      courseURL = updateQueryParams(courseURL, queryParams.course);
    }
    if (queryParams && queryParams.salesStats) {
      salesStatURL = updateQueryParams(salesStatURL, queryParams.course);
    }
    const [last7Days, today] = getDaysInterval(6, null, 'yyyy-mm-dd');
    salesStatURL = updateQueryParams(
      salesStatURL,
      {between: `${last7Days};${today}`}
    );
    return Promise.all([
      http.get(salesURL).then(
        response => {
          dispatch({type: SET_OVERVIEW_SALES, payload: { sales: response.data.data}});
        },
        errorCallBack(
          dispatch,
          {type: SET_OVERVIEW_SALES, payload: {sales : {}}},
          'Error getting Sales'
        ),
      ),
      http.get(salesStatURL).then(
        response => {
          dispatch({type: SET_OVERVIEW_SALES_STATS_7, payload: { salesStat7: response.data.data}});
        },
        errorCallBack(
          dispatch,
          {type: SET_OVERVIEW_SALES_STATS_7, payload: {salesStat7 : []}},
          'Error getting Sales Statistics'
        )
      ),
      http.get(courseURL).then(
        response => {
          dispatch({type: SET_OVERVIEW_COURSES, payload: { courses: response.data.data}});
        },
        errorCallBack(
          dispatch,
          {type: SET_OVERVIEW_COURSES, payload: {courses : {}}},
          'Error getting Courses'
        )
      )
    ]);
  };
};
