import React, {useEffect, useState} from 'react';
import {EditorState, ContentState, convertToRaw, convertFromHTML, Modifier} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "./RichTextEditor.scss";
import draftToHtml from 'draftjs-to-html';
import {isTypeOf} from "../../../helpers/utility";


function RichTextEditor({defaultContent, onChange, valid})  {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if(defaultContent) {
      const contentBlocks = convertFromHTML(defaultContent);
      const sampleEditorContent = ContentState
        .createFromBlockArray(contentBlocks.contentBlocks, contentBlocks.entityMap);
      setEditorState(EditorState.createWithContent(sampleEditorContent));
    }
  }, [defaultContent]);
  const handlePastedText = (text) => {
    // if they try to paste something they shouldn't let's handle it
    if (text.indexOf('text that you should not past') !== -1) {

      // we'll add a message for the offending user to the editor state
      const newContent = Modifier.insertText(
        this.state.editorState.getCurrentContent(),
        this.state.editorState.getSelection(),
        'nice try, chump!'
      );

      // update our state with the new editor content
      this.onChange(EditorState.push(
        this.state.editorState,
        newContent,
        'insert-characters'
      ));
      return true;
    } else {
      return false;
    }
  };

  return (
    <Editor
      editorState={editorState}
      wrapperClassName={`bg-white rounded RichTextEditor ${valid ? '' : 'error'}`}
      editorClassName="px-3"
      handlePastedText={handlePastedText}
      onEditorStateChange={(eState) => {
        setEditorState(eState);
        if(isTypeOf('Function', onChange)) {
          onChange({target: {
            value: draftToHtml(convertToRaw(eState.getCurrentContent())),
            hasText: eState.getCurrentContent().hasText()
          }});
        }
      }}
    />
  );
}

export default RichTextEditor;
