import http from "../../helpers/http";
import {replaceDynamics} from "../../helpers/url";
import apiRoutes from "../../constants/apiRoutes";
import {SHOW_SNACKBAR, showSnackbar} from "./coreActions";
import {NETWORK_ERROR_MESSAGE} from "../../constants/general";

export const SET_CURRENT_COURSE = "COURSE/SET_CURRENT_COURSE";
export const SET_CURRENT_EDIT_COURSE = "COURSE/SET_CURRENT_EDIT_COURSE";

export const setCurrentCourse = (course) => {
  return dispatch => {
    dispatch({type: SET_CURRENT_COURSE, payload: {data: course}});
  };
};

export const getCourseDetail = id => {
  return dispatch => {
    return http.get(replaceDynamics(apiRoutes.COURSE_DETAIL, {id})).then(
      response => {
        const {data} = response.data;
        dispatch({type: SET_CURRENT_COURSE, payload: { data }});
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting course detail: ${error.message}` }));
        }
        dispatch({type: SET_CURRENT_COURSE, payload: {data : {}}});
      }
    );
  };
};
