import * as actionTypes from "../actions/courseActions";

const initialState = {
  currentCourse: null,
  currentEditCourse: null,
};

const courseReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case actionTypes.SET_CURRENT_COURSE:
        return {
          ...state,
          currentCourse: action.payload.data,
        };
      case actionTypes.SET_CURRENT_EDIT_COURSE:
        return {
          ...state,
          currentEditCourse: action.payload.data,
        };
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default courseReducer;
