import cookie from "./cookie";
import store from "../store";
import {SHOW_SNACKBAR, showSnackbar} from "../store/actions/coreActions";
import {
  attachAccessTokenToUrlQueryParams,
  extractQueryParams, hardRedirect,
  updateQueryParams
} from "./url";
import {DAYS_OF_WEEK, MONTHS_OF_YEAR, NETWORK_ERROR_MESSAGE} from "../constants/general";
import http from "./http";

export const logOut = (clearAuthCookies = true, redirectPath = '') => {
  if(clearAuthCookies) {
    cookie.remove('accessToken');
    cookie.remove('refreshToken');
  }
  let url = `${process.env.REACT_APP_BASE_SERVICE_URL}/auth/logout`;
  if (redirectPath) {
    url = updateQueryParams(url, {redirectPath});
  }
  hardRedirect(url);
};

export const setTitleAndScrollToTop = (...title_segments) => {
  setTitle(...title_segments);
  scroll(0, 0);
};

export const setTitle = (...title_segments) => {
  title_segments = (title_segments || [])
    .filter(s => !(s === null || s === undefined || s === ""));
  title_segments.push("SME Shop: Admin Dashboard");
  document.title = title_segments.join(' | ');
};

export const processHTTPError = error => {
  if(error.response && error.response.data) {
    return error.response.data.data || error.response.data.message;
  }

  if(error.message) {
    store.dispatch({
      type: SHOW_SNACKBAR,
      payload: {
        config: {
          message: error.message,
          verticalAlign: "top",
          horizontalAlign: "right"
        }
      }
    });
  }
  return null;
};

export const scroll = (x = 0, y = 0) => {
  window.scrollTo(x, y);
};

export const isAuthenticated = () => {
  return !!cookie.get('accessToken');
};

export const postAuthHandler = (history, routeTo='/classroom') => {
  const query = extractQueryParams((window.location || {}).href || '');
  if(query && 'redirect' in query) {
    let { redirect, requireIdentity } = query;
    redirect = decodeURIComponent(redirect);
    if(requireIdentity){
      redirect = attachAccessTokenToUrlQueryParams(redirect);
    }
    hardRedirect(redirect);
    return;
  }
  if(history) {
    history.push(routeTo);
  }
};

export const preventEventDefault = (event, prevent = true) => {
  if(event && prevent){
    event.preventDefault();
    event.stopPropagation();
  }
};

export const leadingZero = (num, size= 2) => {
  let s = num+"";
  while (s.length < size) s = "0" + s;
  return s;
};

export const formatDate = (date, format = 'dd.mm.yyyy') => {
  if(!date) {
    return '';
  }
  try {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    let dayName = DAYS_OF_WEEK[d.getDay()];
    const monthName = MONTHS_OF_YEAR[d.getMonth()];
    return format
      .replace(/DD/, dayName)
      .replace(/D/, dayName.substring(0, 3))
      .replace(/dd/, leadingZero(day))
      .replace(/d/, day)
      .replace(/MM/, monthName)
      .replace(/mm/, leadingZero(month))
      .replace(/yyyy/, String(year));
  }catch (e) {
    return '';
  }
};

export const formatTime = totalMilliSeconds => {
  let totalSeconds = totalMilliSeconds / 1000;
  let h, m, s;
  h = Math.floor(totalSeconds/ (60 * 60));
  totalSeconds = totalSeconds - h * 60 * 60;
  m = Math.floor(totalSeconds / 60);
  s = totalSeconds - m * 60;
  return `${leadingZero(h)}:${leadingZero(m)}:${leadingZero(s)}`;
};

export const isTypeOf = (type, variable) => {
  return variable && {}.toString.call(variable) === `[object ${type}]`;
};

export const formatCurrency = (
  amount,
  currencyCode = '&#8358;',
  decimalCount = 0,
  decimal = ":",
  thousands = ",") => {
  try {
    if(isNaN(parseInt(amount))) {
      return '';
    }
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + currencyCode +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    return amount;
  }
};

const addDays = (date, days) => {
  date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
};

export const getDaysInterval = (interval, endDate = null, format = '') => {
  endDate = endDate || new Date();
  let end = endDate;
  let start = addDays(endDate, -interval);
  if(format) {
    start = formatDate(start, format);
    end = formatDate(end, format);
  }
  return [start, end];
};

export const getDates = (startDate, stopDate = null, format = '') => {
  let dateArray = [];
  if(!(startDate && stopDate)) {
    return null;
  }
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    let d = new Date (currentDate);
    if (format) {
      d = formatDate(d, format);
    }
    dateArray.push(d);
    currentDate = addDays(currentDate,1);
  }
  return dateArray;
};

export const getObjectPropertyValue = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (o && k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const mapGraphData = (startDate, stopDate, data = null, valueKey, keyKey = 'date') => {
  let daysArray = getDates(startDate, stopDate, 'yyyy-mm-dd');
  if(!data || !data.length) {
    return data;
  }
  const dataCopy = (data || []).reduce((p, c) => {
    p[c[keyKey]] = c[valueKey];
    return p;
  }, {});
  daysArray = daysArray.map(d => [new Date(d).getUTCDate(), parseInt(dataCopy[d], 10) || 0]);
  return daysArray;
};


export const errorCallBack = (dispatch, defaultSuccess, message) => {
  return error => {
    if(!error.response) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: { config: {message: NETWORK_ERROR_MESSAGE} }
      });
    } else {
      dispatch(showSnackbar({ message: `${message}: ${error.message}` }));
    }
    dispatch(defaultSuccess);
  };
};

export const getOptimisedImageUrl = (url, config) => {
  const { width, fetchFormat } = config || {};
  if (url && url.includes('res.cloudinary.com')) {
    return url.replace(
      '/image/upload',
      `/image/upload/w_${width || 680},f_${fetchFormat || 'auto'}`,
    );
  }
  return url;
};

export const getRandomColor = () => {
  const colors = [
    '#015958',
    '#FF07CC',
    '#6E3BFE',
    '#11059c',
    '#AA60EA',
    '#FFA82B',
    '#3b004f',
    '#FF6868',
    '#4d2d01',
    '#820730',
  ];
  const color = colors[Math.floor(Math.random() * colors.length)];
  return {
    color,
    background: `${color}24`,
  };
};

export const clearCache = async () => {
  return http
    .get(`${process.env.REACT_APP_BASE_SERVICE_URL}/api/v1/clear-system-cache`);
};

export default {
  setTitle,
  setTitleAndScrollToTop,
  scroll,
  isAuthenticated,
  postAuthHandler
};
