import React, { Component } from "react";
import "./Aside.scss";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../../assets/icons/logo-white-bg.svg";
import {isTypeOf, logOut} from "../../../helpers/utility";
import icons from "../../../constants/icons";

class Aside extends Component {
  navClasses = [String(this.props.className || ''), 'NavWrapper'];

  links = [
    {
      text: "Overview",
      target: "/",
      icon: icons.Overview,
      isActive: ()=> this.props.location.pathname === '/',
    },
    {
      text: "Classroom Admin",
      target: "/classroom-admin",
      requirements: ['CREATE_COURSE'],
      icon: icons.Classroom,
    },
    {
      text: "Course Categories",
      target: "/categories",
      requirements: ['CREATE_COURSE'],
      icon: icons.Category,
    },
    {
      text: "Event Manager",
      target: "/events",
      icon: icons.EventManager,
    },
    {
      text: "Users",
      icon: icons.User,
      iconClassName: 'dark-bg',
      requirements: ['VIEW_USER'],
      target: "/users",
    },
    {
      text: "Community",
      target: "/community",
      icon: icons.Community,
    },
    {
      text: "Reviews & Ratings",
      target: "/ratings",
      icon: icons.Review,
      requirements: ['CREATE_COURSE', 'VIEW_USER'],
    },
    {
      text: "Coupons",
      target: "/coupons",
      icon: icons.Coupon,
    },
    {
      text: "Manage Orders",
      target: "/manage-orders",
      icon: icons.Manage,
      requirements: ['VIEW_SYSTEM_OVERVIEW'],
    },
    {
      text: "Settings",
      target: "/settings",
      icon: icons.Setting,
    },
    {
      text: "CMS",
      target: "/cms",
      icon: icons.Classroom,
      requirements: ['CREATE_BLOG_POST'],
    },
    {
      text: "Log out",
      target: "#",
      icon: icons.Logout,
      props: {
        onClick: event => {
          event.preventDefault();
          event.stopPropagation();
          logOut();
        }
      }
    }
  ];

  isActive = link => isTypeOf('Function', link.isActive) ?
    link.isActive() :
    this.props.location.pathname.startsWith(link.target);

  render() {
    return (
      <aside className={this.navClasses.join(" ")}>
        <div className="Aside col-12 px-4">
          <div className="NavContent">
            <div className={`NavItem NavIcon`}>
              <Link to="/" className="d-flex align-items-center text-decoration-none">
                <img src={Logo}
                  alt="" className={`header-logo`} style={{width: "3rem", maxWidth: "100%"}}/>
              </Link>
            </div>
            <div className={`NavBody pb-3`}>
              <h3 className="font-s-normal font-w-smbd mt-2 mb-4">Main menu</h3>
              {this.links.filter((p) => {
                return !p.requirements ||
                  (p.requirements && p.requirements.some(r => this.props.currentUserPrivileges[r]));
              }).map((l, index) => (
                <Link key={index} to={l.target} {...l.props || {}}
                  className={`NavItem d-flex align-items-center text-decoration-none ${
                    this.isActive(l) ? "Active" : ''
                  }`}>
                  <img className={`Icon ${l.iconClassName || ''}`} src={l.icon} alt=""/>
                  <span className="ml-1">{l.text}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

export default withRouter(Aside);
