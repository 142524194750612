import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {extractQueryParams} from "../../helpers/url";
import cookie from "../../helpers/cookie";
import {connect} from "react-redux";
import {isAuthenticated, logOut} from "../../helpers/utility";
import {getCurrentUser, SET_CURRENT_USER_PRIVILEGES} from "../../store/actions/coreActions";
import routes from "../../constants/routes";
import PageLoader from "../PageLoader/PageLoader";

class CheckAuth extends Component {
  state = {
    checkingAuthorization: true
  };

  constructor(props) {
    super(props);
    this.queryParams = extractQueryParams(this.props.location.search);
  }

  componentDidMount() {
    this.props.dispatch(getCurrentUser());
    if(this.queryParams.accessToken) {
      cookie.put('accessToken', this.queryParams.accessToken);
    }
    if(this.queryParams.refreshToken) {
      cookie.put('refreshToken', this.queryParams.refreshToken);
    }
    if(this.queryParams.accessToken || this.queryParams.refreshToken) {
      this.props.history.push("/");
    }
    if(!isAuthenticated()) {
      logOut(false, '/auth/sign-in');
    }
    this.preloadRoutes();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.checkingAuthorization) {
      if (this.props.user && (
        !this.props.user.role || this.props.user.role.category !== 'SYSTEM_ADMIN'
      )) {
        logOut();
      }
      if (this.props.user && this.props.user.role &&
        this.props.user.role.category === 'SYSTEM_ADMIN') {
        this.setState({checkingAuthorization: false});
        this.setCurrentUserPrivileges(this.props.user.role.privileges);
      }
    }
  }

  preloadRoutes() {
    routes.forEach(r => {
      if(r.preload && !r.loaded) {
        r.component.preload();
        r.loaded = true;
      }
    });
  }

  setCurrentUserPrivileges(userPrivileges) {
    const privileges = userPrivileges.reduce((p, c) => {
      p[c.privilege] = c.id;
      return p;
    }, {});
    this.props.dispatch({ type: SET_CURRENT_USER_PRIVILEGES, payload: privileges });
  }

  render() {
    return isAuthenticated() ?
      this.state.checkingAuthorization ?
        (
          <div className="min-content-height-header col-12 d-flex
          align-items-center justify-content-center">
            <PageLoader/>
          </div>
        )
        : (this.props.children)
      : (
        <div className="min-content-height-header
        col-12 d-flex align-items-center justify-content-center">
          <h1>Authentication Required!</h1>
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    user: state.coreState.currentUser,
  };
};

export default withRouter(connect(mapStateToProps)(CheckAuth));
