import React from "react";
import "./FormMessage.scss";

const FormMessage = props => {
  const classArray = ['FormMessageContainer', props.position || ''];
  return props.message ? (
    <div className={`${classArray.join(" ")} ${props.className || ''}`}>
      <span id={props.id} className="w-100 text-center">{String(props.message)}</span>
    </div>
  ) : null;
};

export default FormMessage;
