import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { clearSnackbar } from "../../../store/actions/coreActions";
import snackbarStyle from "./Snackbar.module.scss";
import { Close } from "@material-ui/icons";
import {isTypeOf} from "../../../helpers/utility";

export default function AppSnackbar() {
  const dispatch = useDispatch();

  const { open, config } = useSelector(
    state => state.coreState.snackbar
  );

  function handleClose() {
    dispatch(clearSnackbar());
    if (isTypeOf('Function', config.onClose)) {
      config.onClose();
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: config.verticalAlign || "top",
        horizontal: config.horizontalAlign || "right"
      }}
      open={open}
      autoHideDuration={config.autoHideDuration || 5000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar">
          {config.message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          className={snackbarStyle.noOutline}
          onClick={handleClose}
        >
          <Close/>
        </IconButton>
      ]}
    />
  );
}
