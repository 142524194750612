import http from "../../helpers/http";
import {updateQueryParams} from "../../helpers/url";
import apiRoutes from "../../constants/apiRoutes";
import {errorCallBack} from "../../helpers/utility";

export const SET_EVENTS = "EVENT/SET_EVENTS";


export const getEvents = (type, queryParams = {}) => {
  return dispatch => {
    return http.get(updateQueryParams(apiRoutes.EVENTS, queryParams)).then(
      response => {
        dispatch({type: SET_EVENTS, payload: {type, data: response.data.data}});
      },
      errorCallBack(
        dispatch,
        {type: SET_EVENTS, payload: {type, data : {}}},
        `Error getting ${type} events`
      ),
    );
  };
};
