import { Switch as Sw, withStyles } from "@material-ui/core";

const Switch = withStyles((theme) => ({
  root: {
    width: '2.6rem',
    height: `1.4rem`,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: `0.1rem`,
    color: theme.palette.grey[700],
    '&$checked': {
      transform: 'translateX(1.2rem)',
      color: theme.palette.secondary.main,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: `1.2rem`,
    height: '1.2rem',
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[700]}`,
    borderRadius: '0.8rem',
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Sw);

export default Switch;
