import apiRoutes from "../../constants/apiRoutes";
import {updateQueryParams} from "../../helpers/url";
import http from "../../helpers/http";
import {NETWORK_ERROR_MESSAGE} from "../../constants/general";
import {SHOW_SNACKBAR, showSnackbar} from "./coreActions";

export const SET_ORDER_LIST = "ORDERS/SET_ORDER_LIST";

export const getOrderList = (queryParams) => {
  return dispatch => {
    let orderURL = apiRoutes.SALES;
    if (queryParams) {
      orderURL = updateQueryParams(orderURL, queryParams);
    }
    http.get(orderURL).then(
      response => {
        dispatch({type: SET_ORDER_LIST, payload: { orderList: response.data.data}});
      },
      error => {
        if(!error.response) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: { config: {message: NETWORK_ERROR_MESSAGE} }
          });
        } else {
          dispatch(showSnackbar({ message: `Error getting Sales: ${error.message}` }));
        }
        dispatch({type: SET_ORDER_LIST, payload: {orderList : {}}});
      }
    );
  };
};
