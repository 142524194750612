import * as actionTypes from "../actions/formsActions";
import {updateFormControlValidations, updateFormControlValue} from "../../helpers/form";

const initialState = {
  courseOverview: null,
  basicSettings: null,
  passwordSettings: null,
  communityDiscussion: null,
  courseLesson: null,
  eventForm: null,
  slider: null,
  testimonial: null,
  faq: null,
  insight: null,
  caseStudy: null,
  impression: null,
  acceptInvite: null,
  courseCategory: null,
  userInvitation: null,
  moduleActivity: null,
  couponForm: null,
};

const formsReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case actionTypes.SET_FORM_CONTROL_VALUE:
        try {
          return {
            ...state,
            [action.payload.formName]:
              updateFormControlValue(state[action.payload.formName], action.payload)
          };
        }catch (e) {
          return state;
        }
      case actionTypes.SET_FORM_CONTROL_PROP:
        try {
          return {
            ...state,
            [action.payload.formName]: {
              ...state[action.payload.formName],
              controls: state[action.payload.formName].controls.map(c => {
                if(c.name === action.payload.controlName) {
                  return {
                    ...c,
                    inputProps: {
                      ...c.inputProps,
                      [action.payload.propName]: action.payload.value
                    }
                  };
                }
                return c;
              }),
            }
          };
        }catch (e) {
          return state;
        }
      case actionTypes.SET_FORM_CONTROL_VALIDATIONS:
        try {
          return {
            ...state,
            [action.payload.formName]: updateFormControlValidations(state[action.payload.formName])
          };
        }catch (e) {
          return state;
        }
      case actionTypes.RESET_FORM_DATA:
        try {
          if(!action.payload.formName || !action.payload.form) {
            return state;
          }
          return {
            ...state,
            [action.payload.formName]: {...action.payload.form}
          };
        } catch (e) {
          return state;
        }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default formsReducer;
