import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {classroomRoutes} from "../../constants/routes";

const Classroom = (props) => {
  return (
    <div className="w-100">
      <Switch>
        {classroomRoutes.map(({path, exact, component}) => (
          <Route
            key={`${props.match.path}${path}`}
            exact={exact}
            path={`${props.match.path}${path}`}
            component={component}
          />
        ))}
        <Redirect from="*" to={props.match.path}/>
      </Switch>
    </div>
  );
};

export default Classroom;
