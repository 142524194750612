import validate, {updateErrorClassName} from "./validator";
import {showSnackbar} from "../store/actions/coreActions";

export const extractFormData = (form = {}, onlyDirty = true) => {
  return (form.controls || []).reduce((prev, control) => {
    if(onlyDirty && control.dirty) {
      prev[control.name] = control.value;
    }
    if(!onlyDirty) {
      prev[control.name] = control.value;
    }
    return prev;
  }, {});
};

export const getMultiformSubmitClickHandler =
  (dispatch, formConfig, successHandlers, formIndex, instanceId, type = 'data') =>
    async (uploads = null) => {
      const {allForms, setAllForms} = formConfig;
      const {save, edit} = successHandlers;
      const form = allForms[formIndex];
      const formValidity = validate.form(form);
      form.controls.forEach((c, i) => {
        validate.multiformValidateInput(allForms, formIndex, setAllForms)(i, c.value);
      });
      if(!formValidity.isValid) {
        return;
      }
      const formData = extractFormData(form);
      const dataKeys = Object.keys(formData);
      if(!((dataKeys.length) || (uploads && (uploads.files[0] || {}).file))) {
        dispatch(showSnackbar({ message: `No changes made to ${type}!` }));
        return;
      }
      if(instanceId) {
        await edit(instanceId, formData, uploads);
        return;
      }
      await save(formData, uploads);
    };

export const updateFormControlValue = (previousFormState, option) => {
  const equalValidationResults = validate.equalValidatorAll(
    previousFormState,
    option.controlName,
    option.value
  );
  return {
    ...previousFormState,
    controls: previousFormState.controls.map(c => {
      let validity = {...c.validity};
      if (c.name === option.controlName) {
        validity = validate.control(
          previousFormState,
          option.controlName,
          option.value
        );
        c = {
          ...c,
          value: option.value,
          validity,
          dirty: true,
          inputProps: {
            ...c.inputProps,
            className: updateErrorClassName(c.inputProps.className, validity)
          }
        };
      }
      let myErrors = equalValidationResults.filter(e => e.ctrName === c.name);
      if (myErrors.length > 0) {
        validity = {
          ...validity,
          isValid: false
        };
        myErrors.filter(e =>
          !c.validity.errors.find(em => em.message === e.message)
        ).forEach(e => {
          validity = {
            ...validity,
            errors: validity.errors.concat(e)
          };
        });
      } else if (c.name !== option.controlName) {
        const errors = validity.errors.filter(e => !e.isEqualError);
        validity = {
          ...validity,
          isValid: validity.isValid || !errors.length,
          errors
        };
      }
      c = {
        ...c,
        validity,
        inputProps: {
          ...c.inputProps,
          className: c.value ?
            updateErrorClassName(c.inputProps.className, validity) :
            c.inputProps.className
        }
      };
      return c;
    }),
  };
};

export const updateFormControlValidations = (previousFormState) => {
  const newForm = {
    ...previousFormState,
    controls: previousFormState.controls.map(c => {
      let validity = validate.control(
        previousFormState,
        c.name,
        c.value
      );
      const newErrors = validity.errors.concat(
        c.validity.errors.filter(er => er.isEqualError)
      );
      validity = {
        ...validity,
        isValid: !newErrors.length,
        errors: newErrors
      };
      return {
        ...c,
        validity,
        inputProps: {
          ...c.inputProps,
          className: updateErrorClassName(c.inputProps.className, validity)
        }
      };
    }),
  };
  return {
    ...newForm,
    validity: validate.form(newForm)
  };
};

export const getFormHandlersForIsolatedState = (setFormState) => {
  return {
    inputChangedHandler: (el, event) => {
      let newValue;
      switch (el.parentProps.type) {
        case "switch":
        case "checkbox":
          newValue = event.target.checked;
          break;
        case "rte":
          newValue = event.target.hasText ? event.target.value : '';
          break;
        default:
          newValue = event.target.value;
          break;
      }
      setFormState((prevState) => {
        return updateFormControlValue(prevState, {controlName: el.name, value: newValue});
      });
    },
    resetForm: (newForm = {}) => {
      setFormState(() => ({...newForm}));
    },
    setControlValidations: () => {
      setFormState(updateFormControlValidations);
    },
    extractFormData,
  };
};
