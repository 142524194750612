import * as actionTypes from "../actions/coreActions";

const initialState = {
  snackbar: {
    open: false,
    config: {}
  },
  currentUser: null,
  systemOverview: null,
  overviewData: {
    sales: null,
    salesStat7: null,
    courses: null,
    salesStats: null,
  },
  categories: null,
  userStats: null,
  currentUserPrivileges: null,
};

const coreReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case actionTypes.SHOW_SNACKBAR:
        return {
          ...state,
          snackbar: {
            ...state.snackbar,
            open: true,
            config: action.payload.config
          }
        };
      case actionTypes.SET_CURRENT_USER:
        return {
          ...state,
          currentUser: action.payload.user
        };
      case actionTypes.SET_CURRENT_USER_PRIVILEGES:
        return {
          ...state,
          currentUserPrivileges: action.payload
        };
      case actionTypes.CLEAR_SNACKBAR:
        return {
          ...state,
          snackbar: {
            ...state.snackbar,
            open: false,
            config: {
              ...state.snackbar.config,
              message: ""
            }
          }
        };
      case actionTypes.SET_USERS_STATS:
        return {
          ...state,
          userStats: action.payload.userStats
        };
      case actionTypes.SET_CATEGORIES:
        return {
          ...state,
          categories: action.payload.categories
        };
      case actionTypes.SET_SYSTEM_OVERVIEW:
        return {
          ...state,
          systemOverview: action.payload.systemOverview
        };
      case actionTypes.SET_OVERVIEW_SALES:
        return {
          ...state,
          overviewData: {
            ...state.overviewData,
            sales: action.payload.sales
          }
        };
      case actionTypes.SET_OVERVIEW_SALES_STATS:
        return {
          ...state,
          overviewData: {
            ...state.overviewData,
            salesStats: action.payload.salesStats
          }
        };
      case actionTypes.SET_OVERVIEW_SALES_STATS_7:
        return {
          ...state,
          overviewData: {
            ...state.overviewData,
            salesStat7: action.payload.salesStat7
          }
        };
      case actionTypes.SET_OVERVIEW_COURSES:
        return {
          ...state,
          overviewData: {
            ...state.overviewData,
            courses: action.payload.courses
          }
        };
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default coreReducer;
